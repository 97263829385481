import { CircularProgress, makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import BlogPostDetail from "./BlogPostDetail";
import MainComment from "./MainComment";
import MainBlogImage from "./MainBlogImage";
import { connect } from "react-redux";
import {
  getComments,
  setLoading,
  cleanError,
  cleanAlert,
} from "../../../redux/actions/userAction";
import { useSnackbar } from "notistack";
import Center from "../../../utils/Center";
//! styles
const styles = makeStyles((theme) => ({
  mainPageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  mainTextContainer: {
    backgroundColor: theme.palette.common.postBackground,
    color: theme.palette.primary.contrastText,
    boxShadow: "0 4px 20px rgba(0,0,0,0.19), 0 2px 18px rgba(0,0,0,0.23)",
    maxWidth: "80%",
    minWidth: "80%",
    margin: "2rem auto",
    padding: "1rem 2rem",
    borderRadius: "15px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      margin: "1.3rem auto",
      maxWidth: "97%",
      minWidth: "97%",
      padding: "1rem ",
    },
  },
  mainImageContainer: {
    maxHeight: "300px",
    width: "auto",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      maxHeight: "500px",
    },
  },
  mainImgStyle: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  authorProfileImg: {
    height: "4rem",
    width: "4rem",
    borderRadius: "50%",
    zIndex: 97,
    position: "absolute",
    top: "-3%",
    left: "50%",
    overflow: "visible",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("md")]: {
      height: "3rem",
      width: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "2.5rem",
      width: "2.5rem",
      top: "-1.5%",
    },
    [theme.breakpoints.down("xs")]: {
      height: "2.0rem",
      width: "2.0rem",
      top: "-1.2%",
    },
  },
}));

const BlogPost = ({
  data,

  isLoading,

  error,
  alertData,
  cleanError,
  cleanAlert,
}) => {
  const classes = styles();

  const url =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_URL
      : process.env.REACT_APP_PRODUCTION_URL;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      showAlert(error, "error");
    }
    return () => {
      cleanError();
    };
  }, [error]);

  //! success alert
  useEffect(() => {
    if (alertData) {
      showAlert(alertData, "success");
    }
    return () => {
      cleanAlert();
    };
  }, [alertData]);

  //!show erorr
  const showAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  if (isLoading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }
  return (
    <div>
      <div className={classes.mainPageContainer}>
        <MainBlogImage
          image={`${url}/img/posts/${data.image}`}
          mainPage={false}
        />
        <div className={classes.mainTextContainer}>
          <img
            src={`${url}/img/users/${data.author.photo}`}
            alt="user img"
            className={classes.authorProfileImg}
          />
          <BlogPostDetail data={data} />
        </div>
      </div>
      <MainComment data={data.comment} postId={data._id} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  comments: state.users.comments,
  isLoading: state.users.loading,
  error: state.users.error,
  alertData: state.users.alert,
});
export default connect(mapStateToProps, {
  getComments,
  setLoading,
  cleanError,
  cleanAlert,
})(BlogPost);
