import { createMuiTheme } from "@material-ui/core/styles";
import teal from "@material-ui/core/colors/teal";
import amber from "@material-ui/core/colors/amber";


const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main:amber[500]
    },
    secondary: teal,
    common : {
      black:"#000",
      white:"#fff",
      mainBackground:"#fafafa",
      postBackground:"#fff",
      semiBlack:"#444",
      littleBlack:"#888",
      contrastBackground:"#444",
      redError:"#f82c5f"
    }
  },
  typography: {
    tab: {
      fontFamily: "Raleway",
      fontWeight: "700",
      textTransform: "none",
      fontSize: "1rem",
    },
    fancyButton: {
      fontFamily: "Pacifico",
      fontSize: "1rem",
      textTransform: "none",
      color: "#fff",
    },
  },
});




export default lightTheme
