import { useState } from "react";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import Posts from "./Posts";
import Skills from "./Skills";
import Users from "./Users";
import { connect } from "react-redux";
import Center from "../../../utils/Center";

const styles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "2rem auto",
  },
  btnsStyle: {
    margin: "0 2rem",
    [theme.breakpoints.down("sm")]: {
      margin: "0 1rem",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 5px",
    },
  },
  componentStyle: {
    margin: "2rem auto",
    width:"100%"
    // maxWidth:"80%",
    // padding:"2rem 1rem",
    // boxShadow: "1px 5px 14px 1px rgba(0,0,0,0.31)",
    // borderRadius: "10px",
  },
}));
const MainAdmin = ({ isLoading }) => {
  const classes = styles();
  const [selectedPage, setSelectedPage] = useState("Posts");

  let shownComponent;
  if (selectedPage === "Posts") {
    shownComponent = <Posts />;
  } else if (selectedPage === "Skills") {
    shownComponent = <Skills />;
  } else {
    shownComponent = <Users />;
  }

  if (isLoading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }
  return (
    <div className={classes.container}>
      <div>
        <Button
          variant="contained"
          color="primary"
          className={classes.btnsStyle}
          onClick={() => setSelectedPage("Users")}
        >
          Users
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.btnsStyle}
          onClick={() => setSelectedPage("Posts")}
        >
          Add Post
        </Button>
        <Button
          variant="contained"
          color="default"
          className={classes.btnsStyle}
          onClick={() => setSelectedPage("Skills")}
        >
          Add Skill
        </Button>
      </div>
      <div className={classes.componentStyle}>{shownComponent}</div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.users.loading,
});
export default connect(mapStateToProps)(MainAdmin);
