import { Divider, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const styles = makeStyles((theme) => ({
  mainPostSection: {
    margin: "1rem auto",
    width: "100%",
    boxShadow: "1px 5px 14px 1px rgba(0,0,0,0.31)",
    borderRadius: "10px",
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },

  postsTitle: {
    padding: "1rem 1rem 0 1rem",
  },
  postsText: {
    padding: "1rem",
  },
  subTitle: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  blogMainDate: {
    opacity: "0.7",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
  },
  blogMainAuthor: {
    backgroundColor: theme.palette.secondary.main,
    padding: "3px 10px",
    color: theme.palette.secondary.cotrastText,
    borderRadius: "10px",
    fontWeight: "bold",
  },
}));
const Posts = ({ data }) => {
  const classes = styles();
  return (
    <div className={classes.mainPostSection}>
      <Typography
        variant="h4"
        className={classes.postsTitle}
        component={Link}
        to={`/blog/${data._id}`}
      >
        {data.title}
      </Typography>
      <div className={classes.subTitle}>
        <div className={classes.blogMainDate}>{data.createdAt}</div>
        <span className={classes.blogMainDate}>&nbsp;by&nbsp;</span>
        <Link className={classes.blogMainAuthor} to={"/login"}>
          {data.author.name}
        </Link>
      </div>
      <Divider />
      <div
        dangerouslySetInnerHTML={{ __html: data.text }}
        className={classes.postsText}
      ></div>
    </div>
  );
};

export default Posts;
