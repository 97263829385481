import { useState, useEffect, Fragment } from "react";
import {
  Avatar,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { CloudUpload, Delete } from "@material-ui/icons";
import {
  addSkill,
  getAllSkills,
  deleteSkill,
} from "../../../redux/actions/userAction";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: "0 auto",
    maxWidth: "80%",
    padding: "4rem",
    boxShadow: "1px 5px 14px 1px rgba(0,0,0,0.31)",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "96%",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textArea: {
    color: `${theme.palette.primary.contrastText}`,
  },
  dataContainer: {
    marginBottom: "4rem",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2rem",
    },
  },
  deleteIconStyle: {
    padding: "0 2rem",
    color: "red",
    cursor: "pointer",
  },
}));

const Posts = ({ addSkill, getAllSkills, skills, deleteSkill }) => {
  const classes = useStyles();
  const matchesXs = useMediaQuery("(max-width:600px)");
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [image, setImage] = useState("");
  const url =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_URL
      : process.env.REACT_APP_PRODUCTION_URL;

  useEffect(() => {
    getAllSkills();
  }, []);
  // on SUBMIT form
  const submitForm = (e) => {
    e.preventDefault();
    if (title && text && image) {
      let formData = new FormData();
      formData.append("title", title);
      formData.append("text", text);
      formData.append("image", image);
      addSkill(formData);
    }
  };

  return (
    <Fragment>
      <div>
        <List className={classes.dataContainer}>
          {skills.map((item) => (
            <Fragment key={item._id}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    alt={item._id}
                    src={`${url}/img/about/${item.image}`}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={item.email}
                  secondary={
                    <Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {item.title}
                      </Typography>
                      &nbsp;&nbsp;
                      {item.text}
                    </Fragment>
                  }
                />
                <ListItemIcon
                  className={classes.deleteIconStyle}
                  onClick={() => deleteSkill(item._id)}
                >
                  <Delete />
                </ListItemIcon>
              </ListItem>
              <Divider variant="inset" component="li" />
            </Fragment>
          ))}
        </List>
      </div>
      <div className={classes.mainContainer}>
        <div>
          <form className={classes.form} onSubmit={submitForm}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<CloudUpload />}
              fullWidth
              component="label"
            >
              <input
                type="file"
                hidden
                onChange={(e) => setImage(e.target.files[0])}
              />
              Image
            </Button>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="title"
              label="Title"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <TextField
              id="outlined-textarea"
              label="Text..."
              placeholder="Text"
              multiline
              required
              fullWidth
              rows={matchesXs ? 5 : 10}
              variant="outlined"
              value={text}
              onChange={(e) => setText(e.target.value)}
              InputProps={{
                className: classes.textArea,
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Add Skill
            </Button>
          </form>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  skills: state.users.skills,
});
export default connect(mapStateToProps, {
  addSkill,
  getAllSkills,
  deleteSkill,
})(Posts);
