import { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import MainBlogImage from "./MainBlogImage";
import { getAllPosts } from "../../../redux/actions/userAction";
import { connect } from "react-redux";
import SimpleCard from "../../custom/SimpleCard";
import Center from "../../../utils/Center";
import { CircularProgress, Typography } from "@material-ui/core";
import Posts from "./Posts";
import blogLogo from "../../../../assets/def.jpeg";
import { Fragment } from "react";
import { Helmet } from "react-helmet";

const styles = makeStyles((theme) => ({
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "80%",
    margin: "2rem auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "95%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "98%",
    },
  },
  blogPageContainer: {
    margin: "2rem auto",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  twoPostsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "2rem",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(1,1fr)",
      gap: "1rem",
    },
  },
  mainReasonSection: {
    margin: "1rem auto 2rem auto",
    padding: "2rem",
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: "1rem auto 1rem auto",
      padding: "1rem",
      borderRadius: "10px",
    },
  },
  reasonText: {
    margin: "1rem auto 0 auto",
    color: theme.palette.secondary.contrastText,
  },
  mainTitle: {
    textAlign: "center",
    marginTop: "1.5rem",
  },
}));
const MainBlogPage = ({ getAllPosts, posts, isLoading }) => {
  const classes = styles();

  useEffect(() => {
    getAllPosts();
  }, []);

  //const url = "http://localhost:8520/img/posts/def.jpeg";
  //const url ="https://api.saman-ghanbari.com/img/posts/def.jpeg"
  const firstPost = posts[0];
  const secondPost = posts[1];

  if (posts.length < 2 || isLoading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }
  return (
    <div className={classes.pageContainer}>
      <Helmet>
        <title>Saman | Blog</title>
        <meta name="description" content="Portfolio Saman Ghanbari" />
      </Helmet>
      <div>
        <MainBlogImage image={blogLogo} mainPage={true} />
      </div>

      <div className={classes.blogPageContainer}>
        <div className={classes.twoPostsContainer}>
          <SimpleCard data={firstPost} />
          <SimpleCard data={secondPost} />
        </div>
      </div>

      <div className={classes.mainReasonSection}>
        <Typography variant="h3">What Is This</Typography>
        <Typography variant="body1" className={classes.reasonText}>
          I post stuff that i think are cool to see or read, maybe you'll find
          them useful as well
        </Typography>
      </div>

      {posts.length > 0 && (
        <Fragment>
          <Typography variant="h3" className={classes.mainTitle}>
            Posts
          </Typography>

          {posts.map((item) => (
            <Fragment key={item._id}>
              <Posts data={item} />
            </Fragment>
          ))}
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  posts: state.users.posts,
  isLoading: state.users.loading,
});

export default connect(mapStateToProps, { getAllPosts })(MainBlogPage);
