
const normalizeText = (txt,len) => {
  let subText
  if(txt.length > len) {
    subText = `${txt.slice(0,len)}...`
  }else{
    subText = txt
  }

  return subText
}

export default normalizeText