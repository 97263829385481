import React from "react";
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import lightTheme from "./ui/lightTheme";
import darkTheme from "./ui/darkTheme";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { SnackbarProvider } from "notistack";
//!body parts
import { loadUser } from "./redux/actions/userAction";
import PrivateRoute from "./utils/PrivateRoute";
import Header from "./ui/header/Header";
import Footer from "../components/ui/footer/Footer";
import Home from "./ui/pages/home/Home";
import About from "./ui/pages/about/About";
import Login from "./ui/pages/users/Login";
import Register from "./ui/pages/users/Register";
import ChoosenBlog from "./ui/pages/blog/ChoosenBlog";
import ForgetPassword from "./ui/pages/users/ForgetPassword";
import Profile from "./ui/pages/users/Profile";
import NotFound from "./ui/pages/404/NotFound";
import MainAdmin from "./ui/pages/admin/MainAdmin";
import MainBlogPage from "./ui/pages/blog/MainBlogPage";
import ResetPassword from "./ui/pages/users/ResetPassword";
//! Main
const App = ({ choosenTheme }) => {
  return (
    <ThemeProvider theme={choosenTheme === "light" ? lightTheme : darkTheme}>
      <SnackbarProvider maxSnack={3} preventDuplicate>
        <BrowserRouter>
          <Header />
          <Switch>
            <Route path="/blog" exact component={MainBlogPage} />
            <Route path="/blog/:id" exact component={ChoosenBlog} />
            <Route path="/about-us" exact component={About} />
            <Route path="/login" exact component={Login} />
            <Route path="/profile" exact component={Profile} />
            <Route path="/forgot-password" exact component={ForgetPassword} />
            <Route
              path="/reset-password/:resetToken"
              exact
              component={ResetPassword}
            />
            <Route exact path="/register" component={Register} />
            <PrivateRoute path="/saman" exact component={MainAdmin} />
            <Route path="/404" exact component={NotFound} />
            <Route path="/" exact component={Home} />
            <Redirect to="/404" />
          </Switch>
          <Footer />
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  choosenTheme: state.users.theme,
  loading: state.users.loading,
});

export default connect(mapStateToProps, { loadUser })(App);
