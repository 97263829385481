import { Button, makeStyles, TextField } from "@material-ui/core";
import { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { magicAuth } from "../../../redux/actions/userAction";
const style = makeStyles({
  passwordStyle: {
    margin: "1rem 0",
  },
});
const HiddenProfileCheck = ({magicAuth}) => {
  const [password, setPassword] = useState("");
  const classes = style();
  const history = useHistory();
  const submit = (e) => {
    e.preventDefault();
    if(password){
      magicAuth({password, history});
    }
  };
  return (
    <div>
      <form onSubmit={submit}>
        <TextField
          variant="outlined"
          fullWidth
          id="password"
          type="password"
          label="Password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={classes.passwordStyle}
        />
        <Button variant="contained" color="secondary" type="submit" fullWidth>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default connect(null, { magicAuth })(HiddenProfileCheck);
