import { Divider, makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  commentContainer: {
    margin: "2rem auto",
    padding: "1rem",
    borderRadius: "10px",

    backgroundColor: `${theme.palette.primary.main}`,
  },
  commentTextContainer: {
    padding: "2rem",
    fontSize: "1.2rem",
    fontFamily: "Roboto",
  },
  commentHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem",
  },
  commentorContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  commentorName: {
    color: `${theme.palette.primary.contrastText}`,
    backgroundColor: `${theme.palette.secondary.main}`,
    padding: "5px 10px",
    borderRadius: "5px",
    margin: "0 10px",
  },
  commentorPic: {
    height: "3rem",
    width: "3rem",
    borderRadius: "50%",
  },
  commentDate: {
    opacity: "0.7",
    [theme.breakpoints.down("xs")]:{
        fontSize:"12px"
    }
  },
  blogDevider: {
    backgroundColor: "#999",
  },
}));

const EachComment = ({ item }) => {
  const classes = styles();
  const url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_URL
    : process.env.REACT_APP_PRODUCTION_URL;

  return (
    <div className={classes.commentContainer}>
      <div className={classes.commentHeader}>
        <div className={classes.commentorContainer}>
          <img
            src={`${url}/img/users/default-photo.jpg`}
            alt={item.date}
            className={classes.commentorPic}
          />
          <div className={classes.commentorName}>{item.name}</div>
        </div>
        <div className={classes.commentDate}>
          {new Date(item.date).toLocaleDateString(undefined, {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </div>
      </div>
      <Divider variant="fullWidth" classes={{ root: classes.blogDevider }} />
      <div className={classes.commentTextContainer}>{item.text}</div>
    </div>
  );
};

export default EachComment;
