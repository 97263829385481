import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link as routerLink } from "react-router-dom";
import Center from "../../../utils/Center";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  sendResetLink,
  cleanError,
  cleanAlert,
} from "../../../redux/actions/userAction";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  mainContainer: {
    marginBottom: "3rem",
  },
}));

const ForgotPassword = ({
  isLoading,
  sendResetLink,
  alertData,
  error,
  cleanError,
  cleanAlert,
}) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [emailHelper, setEmailHelper] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const onChange = (e) => {
    let valid;
    switch (e.target.id) {
      case "email":
        setEmail(e.target.value);
        valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          e.target.value.trim()
        );
        if (!valid) {
          setEmailHelper("Invalid Email");
        } else {
          setEmailHelper("");
        }
        break;
      default:
        break;
    }
  };

  //! ALERTS

  useEffect(() => {
    if (error) {
      showAlert(error, "error");
    }
    return () => {
      cleanError();
    };
  }, [error]);

  //! success alert
  useEffect(() => {
    if (alertData) {
      showAlert(alertData, "success");
    }
    return () => {
      cleanAlert();
    };
  }, [alertData]);

  //!show erorr
  const showAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  //! on Submit
  const onSubmit = (e) => {
    e.preventDefault();
    sendResetLink({ email });
  };

  if (isLoading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }
  return (
    <Container component="main" maxWidth="xs" className={classes.mainContainer}>
      <Helmet>
        <title>Forgot Password</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            
            value={email}
            onChange={onChange}
            error={emailHelper.length > 0 ? true : false}
            helperText={emailHelper}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset Password
          </Button>
          <Grid container>
            <Grid item>
              <Link component={routerLink} to="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.users.loading,
  error: state.users.error,
  alertData: state.users.alert,
});

export default connect(mapStateToProps, {
  sendResetLink,
  cleanError,
  cleanAlert,
})(ForgotPassword);
