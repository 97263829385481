import {
  LOGIN_USER,
  REGISTER_USER,
  ERROR,
  SET_ERROR,
  USER_LOADED,
  AUTH_ERROR,
  LOG_OUT,
  SET_LOADING,
  UPDATE_USER,
  SET_VALUE,
  SET_THEME,
  GET_USERS,
  DELETE_USER,
  CLEAN_ALERT,
  GET_POSTS,
  GET_SKILLS,
  DELETE_POST,
  ADD_POST,
  DELETE_SKILL,
  ADD_SKILL,
  VIEW_POST,
  EDIT_POST,
  RESET_PASSWORD,
  RESET_LINK,
  SET_ALERT,
  GET_COMMENTS,
} from "../types";
import axios from "axios";
//import {setValue} from './navAction'

// const url =
//   process.env.NODE_ENV === "development"
//     ? process.env.REACT_APP_DEV_URL
//     : process.env.REACT_APP_PRODUCTION_URL;



export const setValue = (data) => (dispatch) => {
  dispatch({ type: SET_VALUE, payload: data });
};
//change theme
export const setTheme = (data) => (dispatch) => {
  dispatch({ type: SET_THEME, payload: data });
};

//! Sets Loading to true
export const setLoading = () => async (dispatch) => {
  // console.log("set loading");
  dispatch({ type: SET_LOADING });
};

//! LOAD THE USER
export const loadUser = () => async (dispatch) => {
  let headerToken;

  if (localStorage.token) {
    headerToken = `Bearer ${localStorage.token}`;
  } else {
    headerToken = null;
  }

  try {
    //  setLoading();

    const res = await fetch(`/api/v1/users/auth`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: headerToken,
      },
    });
    const data = await res.json();
    if (data.success) {
      dispatch({ type: USER_LOADED, payload: data });
    } else {
      dispatch({ type: AUTH_ERROR, payload: "please Login or Register" });
    }
  } catch (error) {
    dispatch({ type: AUTH_ERROR, payload: "please Login or Register" });
  }
};

//! register user
export const registerUser = ({ formData, history, alertData }) => async (
  dispatch
) => {
  try {
    const res = await axios({
      method: "POST",
      url: `/api/v1/users/register`,
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    });

    if (res.data.success) {
      setValue("/");
      dispatch({ type: REGISTER_USER, payload: { info: res.data, alertData } });
      history.push("/");
    }
  } catch (error) {
    if (!error.response.data.success) {
      dispatch({ type: ERROR, payload: error.response.data.error });
    }
  }
};

//! Login user
export const loginUser = ({ email, password, history, alertData }) => async (
  dispatch
) => {
  try {
    const res = await axios({
      url: `/api/v1/users/login`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: { email, password },
    });

    if (res.data.success) {
      setValue("/");
      dispatch({ type: LOGIN_USER, payload: { info: res.data, alertData } });
      history.push("/");
    }
  } catch (error) {
    if (!error.response.data.success) {
      dispatch({ type: ERROR, payload: error.response.data.error });
    }
  }
};

//! NULL THE ERROR
export const cleanError = () => (dispatch) => {
  dispatch({ type: SET_ERROR });
};
// NULL THE ALERT
export const cleanAlert = () => (dispatch) => {
  dispatch({ type: CLEAN_ALERT });
};
//! user logouts
export const logOut = (logOutMsg) => (dispatch) => {
  dispatch({ type: LOG_OUT, payload: logOutMsg });
};

//! update user profile
export const updateUserProfile = ({ formData, history }) => async (
  dispatch
) => {
  let headerToken;

  if (localStorage.token) {
    headerToken = `Bearer ${localStorage.token}`;
  } else {
    headerToken = null;
  }

  try {
  
    const fetchedData = await axios({
      method: "PATCH",
      url: `/api/v1/users/profile`,

      headers: {
        "Content-Type": "application/json",
        Authorization: headerToken,
      },
      data: formData,
    });
    // const res = await fetchedData.json()

    //console.log(fetchedData);
    if (fetchedData.data.success) {
      dispatch({ type: UPDATE_USER, payload: fetchedData.data });
      history.push("/");
    } else {
      dispatch({ type: ERROR, payload: "Error" });
    }
  } catch (error) {
    // console.log(error);
    dispatch({ type: ERROR, payload: "Error" });
  }
};

//! MAGIC AUTH
export const magicAuth = ({ password, history }) => async (dispatch) => {
  let headerToken;

  if (localStorage.token) {
    headerToken = `Bearer ${localStorage.token}`;
  } else {
    headerToken = null;
  }
  const res = await axios({
    method: "POST",
    url: `/api/v1/users/adminAuth`,
    headers: {
      "Content-Type": "application/json",
      Authorization: headerToken,
    },
    data: JSON.stringify({ pass: password }),
  });
  //console.log(res.data);
  if (res.data.success) {
    history.push("/saman");
  } else {
    dispatch({ type: ERROR, payload: "Error" });
  }
};

//! get ALL USERS
export const getUsers = () => async (dispatch) => {
  let headerToken;

  if (localStorage.token) {
    headerToken = `Bearer ${localStorage.token}`;
  } else {
    headerToken = null;
  }
  try {
    const res = await axios({
      url: `/api/v1/users`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: headerToken,
      },
    });
    if (res.data.success) {
      //  console.log(res.data);
      dispatch({ type: GET_USERS, payload: res.data.data });
    }
  } catch (error) {
    //   console.log(error);
  }
};

//! delete user request
export const deleteUser = (userId) => async (dispatch) => {
  let headerToken;
  // console.log(userId);
  if (localStorage.token) {
    headerToken = `Bearer ${localStorage.token}`;
  } else {
    headerToken = null;
  }
  try {
    const res = await axios({
      url: `/api/v1/users/profile`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: headerToken,
      },
      data: JSON.stringify({ id: userId }),
    });
    if (res.data.success) {
      dispatch({ type: DELETE_USER, payload: res.data.data });
    }
  } catch (error) {
    //  console.log(error);
  }
};

//! ADD NEW POST BY ADMIN
export const addPost = (formData) => async (dispatch) => {
  let headerToken;
  if (localStorage.token) {
    headerToken = `Bearer ${localStorage.token}`;
  } else {
    headerToken = null;
  }

  try {
    const res = await axios({
      url: `/api/v1/posts/`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: headerToken,
      },
      data: formData,
    });
    //  console.log(res.data);
    if (res.data.success) {
      dispatch({ type: ADD_POST, payload: res.data.data });
      //alert that the post was successfully added
      //   console.log("post success added");
    }
  } catch (error) {
    // console.log(error);
  }
};

//! ADD NEW Skill BY ADMIN
export const addSkill = (formData) => async (dispatch) => {
  let headerToken;
  if (localStorage.token) {
    headerToken = `Bearer ${localStorage.token}`;
  } else {
    headerToken = null;
  }

  try {
    const res = await axios({
      url: `/api/v1/about/`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: headerToken,
      },
      data: formData,
    });

    if (res.data.success) {
      dispatch({ type: ADD_SKILL, payload: res.data.data });

      //alert that the post was successfully added
      //console.log("post success added");
    }
  } catch (error) {
    //  console.log(error);
  }
};

//! Get all posts for home page on useEffect
export const getAllPosts = () => async (dispatch) => {
  try {
    const res = await axios({
      url: `/api/v1/posts`,
      method: "GET",
    });
    dispatch({ type: GET_POSTS, payload: res.data.data });
  } catch (error) {
    //console.log(error.response);
    if (!error.response.data.success) {
      dispatch({ type: ERROR, payload: error.response.data.error });
    }
  }
};

//! GET SINGLE POST BY ITS ID
export const getPostById = (pid) => async (dispatch) => {
  try {
    const res = await axios({
      url: `/api/v1/posts/${pid}`,
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    dispatch({ type: VIEW_POST, payload: res.data.data });
  } catch (error) {
    if (!error.response.data.success) {
      dispatch({ type: ERROR, payload: error.response.data.error });
    }
  }
};

//! edit post
export const editPostById = ({ pid, title, text }) => async (dispatch) => {
  let headerToken;
  if (localStorage.token) {
    headerToken = `Bearer ${localStorage.token}`;
  } else {
    headerToken = null;
  }
  //console.log(pid)
  try {
    const res = await axios({
      url: `/api/v1/posts/${pid}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: headerToken,
      },
      data: JSON.stringify({ title, text }),
    });
    // console.log(res.data)
    if (res.data.success) {
      dispatch({ type: EDIT_POST, payload: res.data.data });
    }
  } catch (error) {
    if (!error.response.data.success) {
      dispatch({ type: ERROR, payload: error.response.data.error });
    }
  }
};

//! get all skills in about page on useEffect
export const getAllSkills = () => async (dispatch) => {
  try {
    const res = await axios({
      url: `/api/v1/about`,
      method: "GET",
    });
    dispatch({ type: GET_SKILLS, payload: res.data.data });
  } catch (error) {
    if (!error.response.data.success) {
      dispatch({ type: ERROR, payload: error.response.data.error });
    }
  }
};

//! Delete A post in admin panel with id
export const deletePost = (pid) => async (dispatch) => {
  let headerToken;
  if (localStorage.token) {
    headerToken = `Bearer ${localStorage.token}`;
  } else {
    headerToken = null;
  }

  try {
    const res = await axios({
      url: `/api/v1/posts/${pid}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: headerToken,
      },
    });
    if (res.data.success) {
      dispatch({ type: DELETE_POST, payload: res.data.data });
    }
  } catch (error) {
    if (!error.response.data.success) {
      dispatch({ type: ERROR, payload: error.response.data.error });
    }
  }
};

//! delete single skill by admin and its Id
export const deleteSkill = (sid) => async (dispatch) => {
  let headerToken;
  if (localStorage.token) {
    headerToken = `Bearer ${localStorage.token}`;
  } else {
    headerToken = null;
  }

  try {
    const res = await axios({
      url: `/api/v1/about/${sid}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: headerToken,
      },
    });
    if (res.data.success) {
      dispatch({ type: DELETE_SKILL, payload: res.data.data });
    }
  } catch (error) {
    if (!error.response.data.success) {
      dispatch({ type: ERROR, payload: error.response.data.error });
    }
  }
};

//! send reset link to users email
export const sendResetLink = ({ email }) => async (dispatch) => {
  try {
    const res = await axios({
      url: `/api/v1/users/forgetPassword`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ email }),
    });
    if (res.data.success) {
      dispatch({ type: RESET_LINK, payload: res.data.data });
    }
  } catch (error) {
    // console.log(error.response);
    if (!error.response.data.success) {
      dispatch({ type: ERROR, payload: error.response.data.error });
    }
  }
};

//! reset Password
export const resetPassword = ({
  resetToken,
  password,
  confirmPassword,
  history,
}) => async (dispatch) => {
  let successMessage = "Your Password Changed Successfully";
  try {
    const res = await axios({
      url: `/api/v1/users/resetpassword/${resetToken}`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ password, confirmPassword }),
    });
    // console.log(res.data);
    if (res.data.success) {
      setValue("/");
      dispatch({ type: RESET_PASSWORD, payload: successMessage });
      history.push("/");
    }
  } catch (error) {
    //console.log(error.response);
    if (!error.response.data.success) {
      dispatch({ type: ERROR, payload: error.response.data.error });
    }
  }
};

//! addAboutComment
export const addAboutComment = ({
  recaptchaValue,
  name,
  email,
  subject,
  comment,
  alertData,
}) => async (dispatch) => {
  try {
    const res = await axios({
      url: `/api/v1/about/contact`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        captcha: recaptchaValue,
        name,
        email,
        subject,
        comment,
      }),
    });
    if (res.data.success) {
      dispatch({ type: SET_ALERT, payload: alertData });
    }
  } catch (error) {
    if (!error.response.data.success) {
      dispatch({ type: ERROR, payload: error.response.data.error });
    }
  }
};

//! add comment to a post
export const addPostComment = ({ text, postId }) => async (dispatch) => {
  let headerToken;
  if (localStorage.token) {
    headerToken = `Bearer ${localStorage.token}`;
  } else {
    headerToken = null;
  }
  let successMsg = "Your Comment will be added SOON";
  try {
    const res = await axios({
      url: `/api/v1/posts/${postId}`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: headerToken,
      },
      data: JSON.stringify({ text }),
    });
    if (res.data.success) {
      dispatch({ type: SET_ALERT, payload: successMsg });
    }
  } catch (error) {
    if (!error.response.data.success) {
      dispatch({ type: ERROR, payload: error.response.data.error });
    }
  }
};

//! getComments
export const getComments = ({ id }) => async (dispatch) => {
  try {
    const res = await axios({
      url: `/api/v1/posts/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.data.success) {
      dispatch({ type: GET_COMMENTS, payload: res.data.data });
    }
  } catch (error) {
    if (!error.response.data.success) {
      dispatch({ type: ERROR, payload: error.response.data.error });
    }
  }
};
