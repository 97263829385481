import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link as routerLink } from "react-router-dom";
import normalizeText from "../../utils/normalizeText";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    borderRadius: "10px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  simpleCardDescription: {
    padding: "0 2rem",
  },
  bodyTitle : {
    fontSize:"2rem",
    fontWeight:"bold"
  }
});

export default function SimpleCard({ data }) {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={3}>
      <CardContent>
        {/* <Typography className={classes.title} color="textSecondary" gutterBottom>
          Word of the Day
        </Typography> */}
        <Typography variant="h5" component="h2">
          {data.title}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
        {new Date(data.createdAt).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
        </Typography>
        <div
          dangerouslySetInnerHTML={{ __html: normalizeText(data.text, 72) }}
          className={classes.simpleCardDescription}
        >
          {/* {normalizeText(data.text, 172)} */}
        </div>
      </CardContent>
      <CardActions>
        <Button size="small" component={routerLink} to={`/blog/${data._id}`}>
          View
        </Button>
      </CardActions>
    </Card>
  );
}
