import {
  Button,
  Divider,
  makeStyles,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import { useState } from "react";
import EachComment from "./EachComment";
import { connect } from "react-redux";
import { addPostComment } from "../../../redux/actions/userAction";
//! styles
const styles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.common.postBackground,
    color: theme.palette.primary.contrastText,
    boxShadow: "0 4px 20px rgba(0,0,0,0.19), 0 2px 18px rgba(0,0,0,0.23)",
    maxWidth: "80%",
    minWidth: "80%",
    margin: "2rem auto",
    padding: "1rem 2rem",
    borderRadius: "15px",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      margin: "1.3rem auto",
      maxWidth: "97%",
      minWidth: "97%",
      padding: "1rem ",
    },
  },
  headerMain: {
    padding: "1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 3px",
    },
  },
  headerTitle: {
    fontWeight: "bold",
    fontSize: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  yourCommentTitle: {
    fontSize: "1.3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  textAreaContainer: {
    width: "100%",
    padding: "2rem 0",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0",
    },
  },
  textArea: {
    color: `${theme.palette.primary.contrastText}`,
  },
  blogDevider: {
    backgroundColor: "#999",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  mainCommentContainer: {},
}));

const MainComment = ({ data, postId, addPostComment }) => {
  const classes = styles();
  const [text, setText] = useState("");
  const matchesXs = useMediaQuery("(max-width:600px)");

  const onSubmit = (e) => {
    e.preventDefault();
    addPostComment({ text, postId });
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.headerMain}>
        <div className={classes.headerTitle}>Write Something !</div>
      </div>
      <Divider variant="fullWidth" classes={{ root: classes.blogDevider }} />
      <div className={classes.textAreaContainer}>
        <form onSubmit={onSubmit}>
          <TextField
            id="outlined-textarea"
            label="Your Comment ..."
            placeholder="Any Cool ideas ?"
            multiline
            fullWidth
            rows={matchesXs ? 5 : 15}
            variant="outlined"
            InputProps={{
              className: classes.textArea,
            }}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
        </form>
      </div>
      {data && data.length > 0 ? (
        <div className={classes.mainCommentContainer}>
          {data.map((item) => (
            <EachComment item={item} key={item._id} />
          ))}
        </div>
      ) : (
        <div>No Comment Yet :( </div>
      )}
    </div>
  );
};

export default connect(null, { addPostComment })(MainComment);
