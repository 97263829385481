import { CircularProgress } from "@material-ui/core";
import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { getPostById } from "../../../redux/actions/userAction";
import Center from "../../../utils/Center";
import BlogPost from "./BlogPost";

//! main Function
const ChoosenBlog = ({ match, isLoading, data, getPostById }) => {
  useEffect(() => {
    getPostById(match.params.id);
  }, []);

  if (isLoading || !data) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }
  return (
    <Fragment>
      <BlogPost data={data} key={data._id} />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.users.loading,
  data: state.users.viewPost,
});
export default connect(mapStateToProps, { getPostById })(ChoosenBlog);
