import { createMuiTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import deepPurple from "@material-ui/core/colors/deepPurple";

//!dark
const darkTheme = createMuiTheme({
  palette: {
    primary: {
      main : grey[900]
    },
    secondary: deepPurple,
    common : {
      black:"#000",
      white:"#fff",
      mainBackground:"#212121",
      postBackground:"#444",
      semiBlack:"#444",
      littleBlack:"#888",
      contrastBackground:"#fff",
      redError:"#f82c5f"
    }
  },
  typography: {
    tab: {
      fontFamily: "Raleway",
      fontWeight: "700",
      textTransform: "none",
      fontSize: "1rem",
    },
    fancyButton: {
      fontFamily: "Pacifico",
      fontSize: "1rem",
      textTransform: "none",
      color: "#fff",
    },
  },
});



export default darkTheme
