export const SET_VALUE = " SET_VALUE"
//export const GET_VALUE = "GET_VALUE"
export const SET_THEME = "SET_THEME"
export const LOGIN_USER = "LOGIN_USER"
export const REGISTER_USER = "REGISTER_USER"
export const LOGOUT_USER = "LOGOUT_USER"
export const ERROR = "ERROR"
export const SET_ERROR = "SET_ERROR"
export const AUTH_ERROR = "AUTH_ERROR"
export const USER_LOADED = "USER_LOADED"
export const LOG_OUT = "LOG_OUT"
export const SET_LOADING = "SET_LOADING"
export const UPDATE_USER = "UPDATE_USER"
export const GET_USERS = "GET_USERS"
export const DELETE_USER = "DELETE_USER"
export const CLEAN_ALERT = "CLEAN_ALERT"
export const GET_POSTS = "GET_POSTS"
export const GET_SKILLS = "GET_SKILLS"
export const DELETE_POST = "DELETE_POST"
export const ADD_POST = "ADD_POST"
export const DELETE_SKILL = "DELETE_SKILL"
export const ADD_SKILL = "ADD_SKILL"
export const VIEW_POST = "VIEW_POST"
export const EDIT_POST = "EDIT_POST"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_LINK = "RESET_LINK"
export const SET_ALERT= "SET_ALERT"
export const GET_COMMENTS= "GET_COMMENTS"