import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    boxShadow: "0 10px 20px rgba(0,0,0,0.14), 0 6px 6px rgba(0,0,0,0.16)",
    borderRadius: "13px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: "100%",
      textAlign: "center",
    },
  },
  cardTitle: {
    textAlign: "center",
  },
  cardDetail: {
    textAlign: "center",
  },
}));

const CustomSimpleCard = ({ data }) => {
  const classes = useStyles();
  const url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_URL
    : process.env.REACT_APP_PRODUCTION_URL;

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt={data.title}
          height="250"
          image={`${url}/img/about/${data.image}`}
          title={data.title}
          className={classes.cardImageStyle}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={classes.cardTitle}
          >
            {data.title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.cardDetail}
          >
            {data.text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CustomSimpleCard;
