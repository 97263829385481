import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import Contacts from "@material-ui/icons/Contacts";
import { useRef, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import {
  addAboutComment,
  cleanAlert,
  cleanError,
  setLoading,
} from "../../../redux/actions/userAction";
import Center from "../../../utils/Center";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  mainContainer: {
    marginBottom: "3rem",
  },
  textAreaContainer: {
    width: "100%",
    padding: "2rem 0",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0",
    },
  },
  textArea: {
    color: `${theme.palette.primary.contrastText}`,
  },
  captchaStyle: {
    width: "100%",
  },
  captchaContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
  },
}));

const Contact = ({
  setLoading,
  isLoading,
  addAboutComment,
  error,
  alertData,
  cleanAlert,
  cleanError,
}) => {
  const classes = useStyles();
  const matchesXs = useMediaQuery("(max-width:600px)");
  const recaptchaRef = useRef();
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue && email && subject && comment) {
      setLoading();
      addAboutComment({
        recaptchaValue,
        name,
        email,
        subject,
        comment,
        alertData: "Sent Successfully, Thank you for sharing ur thoughts",
      });
    } else {
      showAlert("please provide all the necessary info", "error");
      // setOpenAlert(true);
      // setAlertMsg("please provide all the necessary info");
    }
  };
  useEffect(() => {
    if (error) {
      showAlert(error, "error");
    }
    return () => {
      cleanError();
    };
  }, [error]);
  //! success alert
  useEffect(() => {
    if (alertData) {
      showAlert(alertData, "success");
    }
    return () => {
      cleanAlert();
    };
  }, [alertData]);

  //!show erorr
  const showAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  if (isLoading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }
  return (
    <Container component="main" maxWidth="sm" className={classes.mainContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Contacts />
        </Avatar>
        <Typography component="h1" variant="h5">
          Contact Me
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="subject"
                label="Subject"
                name="subject"
                autoComplete="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="outlined-textarea"
                label="Your Comment ..."
                placeholder="Any Cool ideas ?"
                multiline
                fullWidth
                required
                rows={matchesXs ? 5 : 15}
                variant="outlined"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                InputProps={{
                  className: classes.textArea,
                }}
              />
            </Grid>
            <div className={classes.captchaContainer}>
              <ReCAPTCHA
                sitekey="6LeWflkaAAAAAM8aRqLMnnxwAvgGOMdDoMLucibP"
                ref={recaptchaRef}
                size="normal"
                className={classes.captchaStyle}
              />
            </div>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Send
          </Button>
        </form>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  alertData: state.users.alert,
  error: state.users.error,
  isLoading: state.users.loading,
});

export default connect(mapStateToProps, {
  addAboutComment,
  cleanAlert,
  cleanError,
  setLoading,
})(Contact);
