import { makeStyles } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";

const styles = makeStyles((theme) => ({
  infoContainer: {
    background: theme.palette.secondary.dark,
    color: theme.palette.grey[100],
  },
  infoInnerWidth: {
    width: "100%",
    height: "100%",
    maxWidth: "80%",
    padding: "1rem",
    display: "grid",
    flexDirection: "row",
    gridTemplateColumns: "repeat(3,1fr)",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(1,1fr)",
    },
  },
  infoItem: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem 0",
  },
  eachItem: {
    padding: "4px 0",
    fontWeight: "bold",
    fontSize: "1.2rem",
    [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "repeat(1,1fr)",
        fontSize: "1rem",
    }
  },
}));

const Info = () => {
  const classes = styles();

  return (
    <div className={classes.infoContainer}>
      <div className={classes.infoInnerWidth}>
        <div className={classes.infoItem}>
          <span className={classes.eachItem}>
            <EmailIcon fontSize="large" />
          </span>
          <span className={classes.eachItem}>Email</span>
          <span className={classes.eachItem}>Saman.Ghanbari.me@gmail.com</span>
        </div>
        <div className={classes.infoItem}>
          <span className={classes.eachItem}>
            <PhoneIcon fontSize="large" />
          </span>
          <span className={classes.eachItem}>Phone</span>
          <span className={classes.eachItem}>+98-912-516-2513</span>
        </div>
        <div className={classes.infoItem}>
          <span className={classes.eachItem}>
            <RoomIcon fontSize="large" />
          </span>
          <span className={classes.eachItem}>Address</span>
          <span className={classes.eachItem}>Tehran, Iran</span>
        </div>
      </div>
    </div>
  );
};

export default Info;
