import Center from "../../../utils/Center";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import HiddenProfileCheck from "./HiddenProfileCheck";
import { useSnackbar } from "notistack";

import {
  Divider,
  makeStyles,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  setLoading,
  logOut,
  loadUser,
  updateUserProfile,
  setValue,
  cleanAlert,
  cleanError,
} from "../../../redux/actions/userAction";
import { Helmet } from "react-helmet";

const styles = makeStyles((theme) => ({
  mainContainer: {
    maxWidth: "50%",
    margin: "2rem auto",
    boxShadow: "1px 5px 14px 1px rgba(0,0,0,0.31)",
    borderRadius: "10px",
    padding: "2rem",
    fontFamily: "Roboto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "96%",
    },
  },
  accountInfoContainer: {
    textAlign: "center",
  },
  accountInfoContainerTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    padding: "1rem 2rem",
  },
  accountInfo: {
    height: "100%",
    margin: "2rem auto",
    display: "flex",
    flexDirection: "column",
  },

  userPhoto: {
    borderRadius: "50%",
    height: "4.5rem",
    width: "4.5rem",
    margin: "1rem",
  },
  fieldsStyle: {
    margin: "2rem",
    [theme.breakpoints.down("sm")]: {
      margin: "1rem",
    },
  },
  infoSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  logoutBtnStyle: {
    margin: "1rem 0",
    backgroundColor: "#da2938",
  },
}));
const Profile = ({
  loading,
  logOut,
  setValue,
  setLoading,
  loadUser,
  user,
  updateUserProfile,
  alertData,
  error,
  cleanError,
  cleanAlert,
}) => {
  const classes = styles();
  useEffect(() => {
    setLoading();
    loadUser();
  }, []);
  const [name, setName] = useState(!user ? "" : user.name);
  const [image, setImage] = useState();
  const { enqueueSnackbar } = useSnackbar();
  // const url =
  // process.env.NODE_ENV === "development"
  //   ? process.env.REACT_APP_DEV_URL
  //   : process.env.REACT_APP_PRODUCTION_URL;

  const history = useHistory();

  useEffect(() => {
    if (error) {
      showAlert(error, "error");
    }
    return () => {
      cleanError();
    };
  }, [error]);

  //! success alert
  useEffect(() => {
    if (alertData) {
      showAlert(alertData, "success");
    }
    return () => {
      cleanAlert();
    };
  }, [alertData]);

  const showAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };
  //on update profile IMAGE or NAME
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (name) {
      formData.append("name", name);
    }
    if (image) {
      formData.append("photo", image);
    }
    setLoading();
    updateUserProfile({ formData, history });
  };

  const setLogOut = () => {
    setLoading();
    logOut("You Have Logged Out Successfully");
  };

  if (loading || !user) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  return (
    <div className={classes.mainContainer}>
       <Helmet>
        <title>{user.name}s Profile</title>
        <meta name="description" content="Portfolio Saman Ghanbari" />
    </Helmet>
      <form onSubmit={onSubmit}>
        <div className={classes.accountInfoContainer}>
          <h1 className={classes.accountInfoContainerTitle}>
            Your Account Info
          </h1>
          <Divider />
          <div className={classes.accountInfo}>
            <div className={classes.infoSection}>
              <img
                className={classes.userPhoto}
                src={
                  image
                    ? URL.createObjectURL(image)
                    : `/img/users/${user.photo}`
                }
                alt={user.name}
              />
              <Button
                variant="contained"
                component="label"
                color="primary"
                fullWidth
                className={classes.fieldsStyle}
              >
                <input
                  type="file"
                  hidden
                  onChange={(e) => setImage(e.target.files[0])}
                />
                Upload new photo
              </Button>
            </div>
            <div className={classes.infoSection}>
              <label className={classes.fieldsStyle}>Email</label>
              <TextField
                value={user.email}
                disabled
                variant="filled"
                fullWidth
                className={classes.fieldsStyle}
              />
            </div>
            <div className={classes.infoSection}>
              <label className={classes.fieldsStyle}>Name</label>
              <TextField
                value={name}
                variant="outlined"
                onChange={(e) => setName(e.target.value)}
                fullWidth
                id="name"
                label="Name"
                name="name"
                className={classes.fieldsStyle}
              />
            </div>
          </div>

          <Button variant="contained" color="secondary" fullWidth type="submit">
            Save
          </Button>
          <Button
            variant="text"
            fullWidth
            component={Link}
            to="/"
            onClick={() => {
              setLogOut();
              setValue("/");
            }}
            className={classes.logoutBtnStyle}
          >
            Logout
          </Button>
        </div>
      </form>
      {user.role === "admin" ? <HiddenProfileCheck /> : ""}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.users.user,
  loading: state.users.loading,
  alertData: state.users.alert,
  error: state.users.error,
});

export default connect(mapStateToProps, {
  updateUserProfile,
  setLoading,
  loadUser,
  logOut,
  setValue,
  cleanAlert,
  cleanError,
})(Profile);
