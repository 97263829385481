import React, { useEffect, Fragment } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import Typewriter from "typewriter-effect";
import { connect } from "react-redux";
import Particles from "react-tsparticles";
import homeEffect from "./homeEffect.json";
import {
  setLoading,
  loadUser,
  cleanAlert,
  getAllPosts,
} from "../../../redux/actions/userAction";
import { useSnackbar } from "notistack";
import CustomCard from "../../custom/CustomCard";
import Center from "../../../utils/Center";
import { Helmet } from "react-helmet";
//! styles
const styles = makeStyles((theme) => ({
  typeWriterContainer: {
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90%",
    fontSize: "5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4rem",
    },
  },
  mainPostContainer: {
    color: theme,
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    backgroundColor: theme.palette.common.mainBackground,
  },
  postContainer: {
    maxWidth: "80%",
    margin: "1rem auto",

    width: "100%",
    borderRadius: "8px",
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gap: "1.2rem",
    [theme.breakpoints.down("md")]: {
      maxWidth: "90%",
      gridTemplateColumns: "repeat(3,1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
      gridTemplateColumns: "repeat(2,1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "95%",
      gridTemplateColumns: "repeat(1,1fr)",
    },
  },
  latestPostTitle: {
    margin: "1rem auto",
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: "2.5rem",
    color: theme.palette.primary.contrastText,
  },
  noPostStyle: {
    fontFamily: "Roboto",
    margin: "3rem auto",
    padding: "1rem",
    fontSize: "2rem",
    color: theme.palette.common.redError,
  },
}));

//! main function
const Home = ({
  setLoading,
  loadUser,
  alertData,
  cleanAlert,
  isLoading,
  getAllPosts,
  posts,
}) => {
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();
  // const IOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  useEffect(() => {
    setLoading();
    loadUser();
  }, []);

  //! success alert
  useEffect(() => {
    if (alertData) {
      showAlert(alertData, "success");
    }
    return () => {
      cleanAlert();
    };
  }, [alertData]);

  //!show erorr
  const showAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  //! Getting POSTS data
  useEffect(() => {
    getAllPosts();
  }, []);

  if (isLoading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }
  return (
    <Fragment>
      <Helmet>
        <title>Saman | Home</title>
        <meta name="description" content="Portfolio Saman Ghanbari" />
      </Helmet>
      <div style={{ height: "100vh" }}>
        <Particles options={homeEffect} />
        <h1 className={classes.typeWriterContainer}>
          I'm Saman a&nbsp;
          <Typewriter
            options={{
              strings: ["Web Designer", "Web Developer", "Gamer !"],
              autoStart: true,
              loop: true,
            }}
          />
        </h1>
      </div>
      <div className={classes.mainPostContainer}>
        <h1 className={classes.latestPostTitle}>Latest Posts</h1>

        {posts && posts.length > 0 ? (
          <div className={classes.postContainer}>
            {posts.map((item) => (
              <CustomCard data={item} key={item._id} />
            ))}
          </div>
        ) : (
          <h1 className={classes.noPostStyle}>
            There are no posts right now OR server got down for some reason!
            Sorry eitherway
          </h1>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  alertData: state.users.alert,
  isLoading: state.users.loading,
  posts: state.users.posts,
});
export default connect(mapStateToProps, {
  setLoading,
  loadUser,
  cleanAlert,
  getAllPosts,
})(Home);

//Todo latestPostTitle style has problem on safari devices fix it
