import { makeStyles } from "@material-ui/styles";

//

const MainBlogImage = ({ image, mainPage }) => {
  
  const styles = makeStyles((theme) => ({
    img : {
        borderRadius:mainPage ? "10px" : "0px",
        [theme.breakpoints.down("sm")]: {
          display:"none"
        }
      }
    }));
  const classes = styles()


  return (
    <div>
      <div className={classes.mainImageContainer}>
        <img src={image} alt="some img"  className={classes.img}/>
      </div>
    </div>
  );
};

export default MainBlogImage;
