import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link as reactRouter, useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import Center from "../../../utils/Center";

import { useSnackbar } from "notistack";
import {
  loginUser,
  cleanError,
  loadUser,
  setValue,
  cleanAlert
} from "../../../redux/actions/userAction";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: "2rem",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  closeBtnStyle: {
    color: "red",
  },
}));

const Login = ({
  loginUser,
  error,
  cleanError,
  alertData,
  cleanAlert,
  isAuthenticated,
  loadUser,
  setValue,
}) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailHelper, setEmailHelper] = useState("");
  const [passwordHelper, setPasswordHelper] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();

  useEffect(() => {
    if (error) {
      showAlert(error, "error");
    }
    return () => {
      cleanError();
    };
  }, [error]);

  //! success alert
  useEffect(() => {
    if (alertData) {
      showAlert(alertData, "success");
    }
    return () => {
      cleanAlert();
    };
  }, [alertData]);

  //!show erorr
  const showAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

 
  useEffect(() => {
    loadUser();
  }, []);

  //! on close alert box
  // const closeAlert = () => {
  //   setOpenAlert(false);
  //   setAlertMsg(undefined);
  // };

  //! onchange inputs
  const onChange = (e) => {
    let valid;
    switch (e.target.id) {
      case "email":
        setEmail(e.target.value);
        valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          e.target.value.trim()
        );
        if (!valid) {
          setEmailHelper("Invalid Email");
        } else {
          setEmailHelper("");
        }
        break;
      case "password":
        setPassword(e.target.value);
        valid = e.target.value.trim().length > 5;
        if (!valid) {
          setPasswordHelper("password must be more that 6 characters");
        } else {
          setPasswordHelper("");
        }
        break;
      default:
        break;
    }
  };
  //! login user
  const onSubmit = (e) => {
    e.preventDefault();
    setLoading()
    loginUser({
      email,
      password,
      history,
      alertData: "You Logged In Successfully",
    });

  };
  if (loading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }
  // if(isAuthenticated) {
  //   setValue("/")

  // }
  return (
    <Container component="main" maxWidth="xs">
       <Helmet>
        <title>Login</title>
        <meta name="description" content="Portfolio Saman Ghanbari" />
    </Helmet>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            
            value={email}
            onChange={onChange}
            error={emailHelper.length > 0 ? true : false}
            helperText={emailHelper}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={onChange}
            error={passwordHelper.length > 0 ? true : false}
            helperText={passwordHelper}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Login
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                component={reactRouter}
                to="/forgot-password"
                variant="body2"
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link component={reactRouter} to="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  error: state.users.error,
  isAuthenticated: state.users.isAuthenticated,
  alertData : state.users.alert
});

export default connect(mapStateToProps, {
  loginUser,
  cleanError,
  loadUser,
  setValue,
  cleanAlert
})(Login);
