import React,{useEffect,Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import {getUsers,deleteUser} from '../../../redux/actions/userAction';
import {connect} from 'react-redux';
import { ListItemIcon } from '@material-ui/core';
import {Delete} from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  deleteIconStyle: {
    padding:"0 2rem",
    color:"red",
    cursor:"pointer"
  }
}));

const Users = ({data, getUsers, deleteUser}) => {
  const classes = useStyles();

  const { adminUsers } = data;
  const url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_URL
    : process.env.REACT_APP_PRODUCTION_URL;

  useEffect(() => {
    getUsers();
  }, [deleteUser]);
  if (!adminUsers) {
    return (
      <div>
        <h1>NO USERS COULD BE FOUND AT THIS TIME</h1>
      </div>
    );
  }
  return (
    <List className={classes.root}>
      {adminUsers.map(item => (
        <Fragment key={item._id}>
        <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={item._id} src={`${url}/img/users/${item.photo}`} />
        </ListItemAvatar>
        <ListItemText
          primary={item.email}
          secondary={
            <Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {item.name}
              </Typography>&nbsp;&nbsp;
              {item.createdAt}
            </Fragment>
          }
        />
          <ListItemIcon className={classes.deleteIconStyle} onClick={() => deleteUser(item._id)}>
          <Delete />
        </ListItemIcon>
      </ListItem>
      <Divider variant="inset" component="li" />
        </Fragment>
      ))}
    </List>
  );
}


const mapStateToProps = (state) => ({
  data: state.users,
});
export default connect(mapStateToProps, { getUsers,deleteUser })(Users);


