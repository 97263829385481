import { Grid, makeStyles } from "@material-ui/core";
import wave from "../../../assets/wave.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setValue } from "../../redux/actions/userAction";
import InstagramIcon from "@material-ui/icons/Instagram";
import TelegramIcon from "@material-ui/icons/Telegram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";

const styles = makeStyles((theme) => ({
  footerContainer: {
    background: theme.palette.primary.main,
    margin: 0,
    padding: 0,
   borderTop:"0.1px solid #acaba8"
  },
  logo: {
    margin: 0,
    borderImage: 0,
    display:"block"
  },
  copyRight: {
    backgroundColor: "#444",
    color: "#fff",
    width: "100%",
    margin: "0 auto 0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1.5rem 0",
  },
  mainGridContainer: {
    color: "#fff",
    fontWeight: "400",
    fontSize: "1.4rem",
    fontFamily: "Roboto",
    textDecoration: "none",
  },
  gridItem: {
    margin: "3rem",
  },
  responsiveFooterItems : {
    textAlign:"center",
    wordBreak:"keep-all",
    [theme.breakpoints.down("sm")]: {
      fontSize:"1.2rem"
    }
  }
}));

const Footer = ({ setValue }) => {
  const classes = styles();
  return (
    <footer className={classes.footerContainer}>
      <Grid container justify="center" className={classes.mainGridContainer}>
        <Grid item className={classes.gridItem}>
          <Grid container spacing={4}>
            <Grid item component={Link} to="/" onClick={() => setValue("/")}>
              Home
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={classes.gridItem}>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            spacing={4}
          >
            <Grid
              item
              component={Link}
              to="/blog"
              onClick={() => setValue("/blog")}
              
            >
              Blog
            </Grid>
            <Grid
              item
              component={Link}
              to="/blog"
              onClick={() => setValue("/blog")}
              className={classes.responsiveFooterItems}
            >
              Software Development
            </Grid>
            <Grid
              item
              component={Link}
              to="/blog"
              onClick={() => setValue("/blog")}
              className={classes.responsiveFooterItems}
            >
              Mobile Development
            </Grid>
            <Grid
              item
              component={Link}
              to="/blog"
              onClick={() => setValue("/blog")}
              className={classes.responsiveFooterItems}
            >
              Website Development
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={classes.gridItem}>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            spacing={4}
          >
            <Grid
              item
              component={Link}
              to="/login"
              onClick={() => setValue("/log")}
            >
              Login
            </Grid>
            <Grid
              item
              component={Link}
              to="/register"
              onClick={() => setValue("/log")}
            >
              Register
            </Grid>
            <Grid
              item
              component={Link}
              to="/login"
              onClick={() => setValue("/log")}
            >
              Guarding
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={classes.gridItem}>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            spacing={4}
          >
            <Grid
              item
              component={Link}
              to="/about-us"
              onClick={() => setValue("/about-us")}
            >
              About
            </Grid>
            <Grid
              item
              component={Link}
              to="/about-us"
              onClick={() => setValue("/about-us")}
            >
              Team
            </Grid>
            <Grid
              item
              component={Link}
              to="/about-us"
              onClick={() => setValue("/about-us")}
            >
              History
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={classes.gridItem}>
          <Grid container spacing={4} direction="column" alignItems="center">
            <Grid
              item
              component={Link}
              to="/about-us"
              onClick={() => setValue("/about-us")}
            >
              Contact
            </Grid>
            <Grid
              item
              component={"a"}
              href="https://github.com/Saman7z"
              rel="noopener noreferrer"
              target="_blank"
            >
              <GitHubIcon />
            </Grid>
            <Grid
              item
              component={"a"}
              href="https://www.instagram.com/saman7z/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <InstagramIcon />
            </Grid>
            <Grid
              item
              component={"a"}
              href="https://telegram.me/saman7z"
              rel="noopener noreferrer"
              target="_blank"
            >
              <TelegramIcon />
            </Grid>
            {/* <Grid
              item
              component={"a"}
              href="https://www.linkedin.com/in/saman7z"
              rel="noopener noreferrer"
              target="_blank"
            >
              <LinkedInIcon />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      <img src={wave} alt="wave programming" className={classes.logo} />
      <div className={classes.copyRight}>
        <span> &#169; Saman Ghanbari {new Date().getFullYear()}</span>
      </div>
    </footer>
  );
};

// const mapStateToProps = state => ({
//   value:state.navValue.page
// })

export default connect(null, { setValue })(Footer);
