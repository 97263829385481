import { makeStyles } from "@material-ui/core";

const styles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height:"100vh"
  },
});
const Center = ({ children }) => {
  const classes = styles();
  return (
    <div className={classes.container}>
      <div>{children}</div>
    </div>
  );
};

export default Center;
