import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link as routerLink } from "react-router-dom";
import { setValue } from "../../redux/actions/userAction";
import { connect } from "react-redux";
import normalizeText from "../../utils/normalizeText";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    boxShadow: "0 8px 20px rgba(0,0,0,0.19), 0 6px 8px rgba(0,0,0,0.23)",
    borderRadius: "12px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: "100%",
      textAlign: "center",
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expandIconStyle : {
    marginLeft:"auto"
  }
}));

const CustomCard = ({ data, setValue }) => {
  const classes = useStyles();
  const url =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_URL
      : process.env.REACT_APP_PRODUCTION_URL;

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar
            aria-label="recipe"
            className={classes.avatar}
            src={`${url}/img/users/me.jpg`}
            alt={data.title}
          >
            R
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={data.author.name}
        subheader={new Date(data.createdAt).toLocaleDateString(undefined, {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      />
      <CardMedia
        className={classes.media}
        image={`${url}/img/posts/${data.image}`}
        title={data.title}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {normalizeText(data.title, 32)}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <IconButton
          className={classes.expandIconStyle}
          component={routerLink}
          to={`/blog/${data._id}`}
          onClick={() => setValue("/blog")}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default connect(null, { setValue })(CustomCard);
