import { useState, useEffect, Fragment } from "react";
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { CloudUpload, Delete, Edit } from "@material-ui/icons";
import {
  setLoading,
  addPost,
  getAllPosts,
  deletePost,
  editPostById,
  getPostById,
  cleanError,
  cleanAlert,
} from "../../../redux/actions/userAction";
import { connect } from "react-redux";
import normalizeText from "../../../utils/normalizeText";
import Center from "../../../utils/Center";
import { useSnackbar } from "notistack";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: "0 auto",
    maxWidth: "80%",
    padding: "4rem",
    boxShadow: "1px 5px 14px 1px rgba(0,0,0,0.31)",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "96%",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textArea: {
    color: `${theme.palette.primary.contrastText}`,
  },
  deleteIconStyle: {
    padding: "0 2rem",
    color: "red",
    cursor: "pointer",
  },
  dataContainer: {
    marginBottom: "4rem",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2rem",
    },
  },
  inputStyle: {
    margin: "1rem auto 2rem auto",
    height: "420px",
  },
  postsContainer: {
    maxWidth: "80%",
    margin: "0 auto",
  },
}));

const Posts = ({
  isLoading,
  addPost,
  getAllPosts,
  posts,
  deletePost,
  editPostById,
  alertData,
  error,
  cleanError,
  cleanAlert,
}) => {
  const classes = useStyles();
  const matchesXs = useMediaQuery("(max-width:600px)");

  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [image, setImage] = useState("");
  const [selectedPostToEdit, setSelectedPostToEdit] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getAllPosts();
  }, []);

  useEffect(() => {
    if (error) {
      showAlert(error, "error");
    }
    return () => {
      cleanError();
    };
  }, [error]);

  //! success alert
  useEffect(() => {
    if (alertData) {
      showAlert(alertData, "success");
    }
    return () => {
      cleanAlert();
    };
  }, [alertData]);

  //!show erorr
  const showAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  // const url =
  // process.env.NODE_ENV === "development"
  //   ? process.env.REACT_APP_DEV_URL
  //   : process.env.REACT_APP_PRODUCTION_URL;

  //! on SUBMIT form
  const submitForm = (e) => {
    e.preventDefault();
    if (title && text && image) {
      let formData = new FormData();
      formData.append("title", title);
      formData.append("text", text);
      formData.append("image", image);
      selectedPostToEdit
        ? editPostById({ pid: selectedPostToEdit._id, title, text })
        : addPost(formData);
    }
    setSelectedPostToEdit(null);
  };

  const setEditPostItem = (id) => {
    const thePost = posts.find((item) => item._id === id);
    setSelectedPostToEdit(thePost);
    setTitle(thePost.title);
    setText(thePost.text);
    setImage(thePost.image);
  };

  if (isLoading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }
  return (
    <Fragment>
      <div className={classes.postsContainer}>
        <List className={classes.dataContainer}>
          {posts.map((item) => (
            <Fragment key={item._id}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt={item._id} src={`/img/posts/${item.image}`} />
                </ListItemAvatar>
                <ListItemText
                  primary={item.email}
                  secondary={
                    <Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {item.title}
                      </Typography>
                      &nbsp;&nbsp;
                      {normalizeText(item.text, 62)}
                    </Fragment>
                  }
                />
                <ListItemIcon
                  className={classes.deleteIconStyle}
                  onClick={() => {
                    setEditPostItem(item._id);
                  }}
                >
                  <Edit />
                </ListItemIcon>

                <ListItemIcon
                  className={classes.deleteIconStyle}
                  onClick={() => deletePost(item._id)}
                >
                  <Delete />
                </ListItemIcon>
              </ListItem>
              <Divider variant="inset" component="li" />
            </Fragment>
          ))}
        </List>
      </div>
      <div className={classes.mainContainer}>
        <form className={classes.form} onSubmit={submitForm}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<CloudUpload />}
            fullWidth
            component="label"
          >
            <input
              type="file"
              hidden
              onChange={(e) => setImage(e.target.files[0])}
            />
            Image
          </Button>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="title"
            label="Title"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <TextField
            id="outlined-textarea"
            label="Text..."
            placeholder="Text"
            multiline
            required
            fullWidth
            rows={matchesXs ? 5 : 10}
            variant="outlined"
            value={text}
            onChange={(e) => setText(e.target.value)}
            InputProps={{
              className: classes.textArea,
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {selectedPostToEdit ? "Edit POST" : "Add POST"}
          </Button>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  posts: state.users.posts,
  viewPost: state.users.viewPost,
  isLoading: state.users.loading,
  error: state.users.error,
  alertData: state.users.alert,
});
export default connect(mapStateToProps, {
  setLoading,
  addPost,
  getAllPosts,
  deletePost,
  editPostById,
  getPostById,
  cleanError,
  cleanAlert,
})(Posts);
