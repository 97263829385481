import {
  REGISTER_USER,
  LOGIN_USER,
  ERROR,
  SET_ERROR,
  AUTH_ERROR,
  USER_LOADED,
  LOG_OUT,
  SET_LOADING,
  UPDATE_USER,
  SET_VALUE,
  SET_THEME,
  GET_USERS,
  DELETE_USER,
  CLEAN_ALERT,
  GET_POSTS,
  GET_SKILLS,
  DELETE_POST,
  ADD_POST,
  DELETE_SKILL,
  ADD_SKILL,
  VIEW_POST,
  EDIT_POST,
  RESET_PASSWORD,
  RESET_LINK,
  SET_ALERT,
  GET_COMMENTS
} from "../types";

//if(window.location.pathname== "/software-development"){}
//const routes = ["/", "/plans", "/blog", "/about-us", "/sign-in"];
const routes = ["/", "/blog", "/about-us", "/log"];

const initialState = {
  page: routes.includes(window.location.pathname)
    ? window.location.pathname
    : false,
  theme: "light",
  error: null,
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: false,
  user: null,
  role: null,
  adminUsers: null,
  alert: null,
  posts: [],
  skills: [],
  viewPost: null,
  comments:[]
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VALUE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.data,
        loading: false,
      };
    case REGISTER_USER:
    case LOGIN_USER:
      localStorage.setItem("token", action.payload.info.token);

      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.info.data,
        page: "/",
        alert: action.payload.alertData,
        loading: false,
      };

    case ERROR:
    case AUTH_ERROR:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        error: action.payload,
        isAuthenticated: false,
        loading: false,
      };
    case SET_ERROR:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case LOG_OUT:
      localStorage.removeItem("token");
      return {
        ...state,
        isAuthenticated: null,
        token: null,
        user: null,
        loading: false,
        alert: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        page: "/",
        loading: false,
      };
    case GET_USERS:
      return {
        ...state,
        adminUsers: action.payload,
        loading: false,
      };
    case DELETE_USER:
      return {
        ...state,
        adminUsers: state.adminUsers.filter(
          (item) => item._id !== action.payload
        ),
        loading: false,
      };
    case CLEAN_ALERT:
      return {
        ...state,
        alert: null,
      };
    case GET_POSTS:
      return {
        ...state,
        posts: action.payload,
        loading: false,
      };
    case ADD_POST:
      return {
        ...state,
        posts: [...state.posts, action.payload],
        loading: false,
      };
    case DELETE_POST:
      return {
        ...state,
        posts: state.posts.filter((item) => item._id !== action.payload._id),
        loading: false,
      };
    case VIEW_POST:
      return {
        ...state,
        viewPost: action.payload,
        loading: false,
      };
    case EDIT_POST:
      return {
        ...state,
        posts: state.posts.map((item) =>
          item._id === action.payload._id ? action.payload : item
        ),
        loading: false,
      };
    case GET_SKILLS:
      return {
        ...state,
        skills: action.payload,
        loading: false,
      };
    case DELETE_SKILL:
      return {
        ...state,
        skills: state.skills.filter((item) => item._id !== action.payload._id),
        loading: false,
      };
    case ADD_SKILL:
      return {
        ...state,
        skills: [...state.skills, action.payload],
        loading: false,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        alert: action.payload,
        loading: false,
      };
    case RESET_LINK:
      return {
        ...state,
        alert: action.payload,
        loading: false,
      };
    case SET_ALERT:
      return {
        ...state,
        alert: action.payload,
        loading: false,
      };
    case GET_COMMENTS:
      return {
        ...state,
        comments:action.payload,
        loading:false
      }  
    default:
      return state;
  }
};
export default userReducer;
