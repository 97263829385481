import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import {
  cleanAlert,
  cleanError,
  setLoading,
  resetPassword,
} from "../../../redux/actions/userAction";
import Center from "../../../utils/Center";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPassword = ({
  match,
  error,
  cleanError,
  isLoading,
  alertData,
  setLoading,
  resetPassword,
}) => {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [passwordShortnessHelper, setPasswordShortnessHelper] = useState("");
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      showAlert(error, "error");
    }
    return () => {
      cleanError();
    };
  }, [error]);
  useEffect(() => {
    if (alertData) {
      showAlert(alertData, "success");
    }
    return () => {
      cleanAlert();
    };
  }, [alertData]);

  //!show erorr
  const showAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  //! change [pass] validation
  const onChange = (e) => {
    let valid;
    switch (e.target.id) {
      case "password":
        setPassword(e.target.value);
        valid = e.target.value.trim().length > 5;
        if (!valid) {
          setPasswordShortnessHelper("password must be more that 6 characters");
        } else {
          setPasswordShortnessHelper("");
        }
        break;
      case "password2":
        setConfirmPassword(e.target.value);
        valid =
          e.target.value.trim().length > 0 &&
          e.target.value.trim() === password;

        if (!valid) {
          setPasswordMatchError("passwords do not match");
        } else {
          setPasswordMatchError("");
        }
        break;
      default:
        break;
    }
  };

  //! onSubmitForm
  const onSubmit = (e) => {
    e.preventDefault();
    if (password && confirmPassword) {
      setLoading();
      resetPassword({
        resetToken: match.params.resetToken,
        password,
        confirmPassword,
        history,
      });
    } else {
      showAlert("please provide all the necessary info", "error");
    }
  };
  if (isLoading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onChange={onChange}
            helperText={passwordShortnessHelper}
            error={passwordShortnessHelper.length > 0 ? true : false}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password2"
            label="Confirm Password"
            type="password"
            id="password2"
            value={confirmPassword}
            onChange={onChange}
            helperText={passwordMatchError}
            error={passwordMatchError.length > 0 ? true : false}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset
          </Button>
        </form>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  error: state.users.error,
  alertData: state.users.alert,
  isLoading: state.users.loading,
});

export default connect(mapStateToProps, {
  cleanAlert,
  cleanError,
  setLoading,
  resetPassword,
})(ResetPassword);
