import logo from "../../../assets/main-logo.png";
import {
  AppBar,
  Toolbar,
  useScrollTrigger,
  makeStyles,
  Tab,
  Tabs,
  Slide,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  SwipeableDrawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import AppleIcon from "@material-ui/icons/Apple";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import PhoneIcon from "@material-ui/icons/Phone";
import { connect } from "react-redux";
import { setValue, setTheme } from "../../redux/actions/userAction";

//! Navigation Effect
const HideOnScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

//! Custom Styles
const styles = makeStyles((theme) => ({
  logo: {
    height: "6.5rem",
    [theme.breakpoints.down("xs")]: {
      height: "5rem",
    },
  },
  tabs: {
    marginLeft: "auto",
  },
  tab: {
    ...theme.typography.tab,
    minWidth: 12,
    marginLeft: "25px",
  },
  fancyButton: {
    ...theme.typography.fancyButton,
    borderRadius: "50px",
    marginLeft: "50px",
    marginRight: "25px",
    height: "2.4rem",
    fontWeight: "500",
  },
  logoContainer: {
    margin: 0,
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  Servicemenu: {
    backgroundColor: "#3bf5dcce",
    color: "#fff",
    borderRadius: "8px",
  },
  drawerIcon: {
    marginLeft: "auto",
    paddingRight: "1rem",
    "&:hover": {
      backgroundColor: "transparent",
    },
    color: theme.palette.primary.contrastText,
  },
  drawerPaper: {
    backgroundColor: "#FFC107",
  },

  drawerTexts: {
    ...theme.typography.tab,
    color: "white",
  },
  drawerFancyBtn: {
    textAlign: "center",
    margin: "0 auto",
  },
  drawerFancyBtnText: {
    textAlign: "center",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.dark,
    ...theme.typography.fancyButton,
    "&:hover": {
      backgroundColor: "#59ee4b",
    },
    margin: "0 auto",
  },
}));

//! MAIN FUNCTION
const Header = (props) => {
  const { value, setValue, theme, setTheme, isAuthenticated } = props;
  //! variables
  const myStyles = styles();
  // const theme = useTheme();
  // //const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const smallMenu = useMediaQuery("(max-width:1151px)");
  const IOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  //! Hooks

  const [openDrawer, setOpenDrawer] = useState(false);

  //! Tab handler
  const onChangeTabs = (e, tabValue) => {
    setValue(tabValue);
  };

  //! TABS
  const tabs = (
    <Fragment>
      <Tabs className={myStyles.tabs} value={value} onChange={onChangeTabs}>
        <Tab
          label="Home"
          value="/"
          className={myStyles.tab}
          component={Link}
          to="/"
        />
        {/* <Tab
          label="Plans"
          value="/plans"
          className={myStyles.tab}
          component={Link}
          to="/plans"
        /> */}
        <Tab
          label="Blog"
          value="/blog"
          className={myStyles.tab}
          component={Link}
          to="/blog"
        />
        <Tab
          label="About"
          value="/about-us"
          className={myStyles.tab}
          component={Link}
          to="/about-us"
        />
        <Tab
          label={isAuthenticated ? "Profile" : "Login"}
          className={myStyles.tab}
          component={Link}
          to={isAuthenticated ? "/profile" : "/login"}
          value="/log"
          onClick={(e) => {
            setValue("/log");
          }}
        />
      </Tabs>
      <Button
        variant="contained"
        color="secondary"
        className={myStyles.fancyButton}
        onClick={() => {
          setValue(false);
          setTheme(theme === "dark" ? "light" : "dark");
        }}
      >
        {theme === "dark" ? "light" : "dark"}
      </Button>
    </Fragment>
  );

  //! DRAWER
  const drawer = (
    <Fragment>
      <IconButton
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
        className={myStyles.drawerIcon}
      >
        <MenuIcon fontSize="large" onClick={() => setOpenDrawer(!openDrawer)} />
      </IconButton>

      <SwipeableDrawer
        disableBackdropTransition={!IOS}
        disableDiscovery={IOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{ paper: myStyles.drawerPaper }}
      >
        <List className={myStyles.listContainer}>
          <ListItem
            component={Link}
            to="/"
            button
            divider
            value="/"
            onClick={(e) => {
              setOpenDrawer(false);
              setValue("/");
            }}
            className={myStyles.drawerTexts}
            selected={value === "/"}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" className={myStyles.drawerTexts} />
          </ListItem>
          {/* <ListItem
            component={Link}
            to="/plans"
            button
            value="/plans"
            onClick={(e) => {
              setOpenDrawer(false);
              setValue("/plans");
            }}
            selected={value === "/plans"}
          >
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            <ListItemText primary="Plans" className={myStyles.drawerTexts} />
          </ListItem> */}
          <ListItem
            component={Link}
            to="/blog"
            button
            value="/blog"
            onClick={(e) => {
              setOpenDrawer(false);
              setValue("/blog");
            }}
            selected={value === "/blog"}
          >
            <ListItemIcon>
              <AppleIcon />
            </ListItemIcon>
            <ListItemText primary="blog" className={myStyles.drawerTexts} />
          </ListItem>
          <ListItem
            component={Link}
            to="/about-us"
            button
            value="/about-us"
            onClick={(e) => {
              setOpenDrawer(false);
              setValue("/about-us");
            }}
            selected={value === "/about-us"}
          >
            <ListItemIcon>
              <PersonPinIcon />
            </ListItemIcon>
            <ListItemText primary="About" className={myStyles.drawerTexts} />
          </ListItem>

          <ListItem
            component={Link}
            to={isAuthenticated ? "/profile" : "/login"}
            button
            divider
            value="/log"
            onClick={(e) => {
              setOpenDrawer(false);
              setValue("/log");
            }}
            selected={value === "/log"}
          >
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText
              primary={isAuthenticated ? "Profile" : "Login"}
              className={myStyles.drawerTexts}
            />
          </ListItem>

          <ListItem
            value="false"
            onClick={(e) => {
              setOpenDrawer(false);
              setValue("false");
              setTheme(theme === "dark" ? "light" : "dark");
            }}
            className={myStyles.drawerFancyBtn}
          >
            <ListItemText
              primary={theme === "dark" ? "light" : "dark"}
              className={myStyles.drawerFancyBtnText}
            />
          </ListItem>
        </List>
      </SwipeableDrawer>
    </Fragment>
  );
 

  //? Main RETURN START
  return (
    <HideOnScroll {...props}>
      <AppBar position="sticky">
        <Toolbar disableGutters>
          <Button
            component={Link}
            to="/"
            className={myStyles.logoContainer}
            onClick={() => setValue("/")}
            disableRipple
          >
            <img src={logo} alt="blog-logo" className={myStyles.logo} />
          </Button>
          {smallMenu ? drawer : tabs}
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};
//? Main RETURN END

const mapStateToProps = (state) => ({
  value: state.users.page,
  theme: state.users.theme,
  isAuthenticated: state.users.isAuthenticated,
});

export default connect(mapStateToProps, { setValue, setTheme })(Header);
