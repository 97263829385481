import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
const PrivateRoute = ({
  component: Component,
  auth,
  ...rest
}) => {
  const {isAuthenticated, user, loading} = auth

  if(!user) {
    return <Redirect to="/"/>
  }
  return(
    (
      <Route
        {...rest}
        render={(props) =>
          !isAuthenticated && !user && user.role !== "admin" && !loading ? (
            <Redirect to="/" />
          ) : (
            <Component {...props} />
          )
        }
      />
    )
  )
}
const mapStateToProps = (state) => ({
  auth: state.users,
});
export default connect(mapStateToProps)(PrivateRoute);
