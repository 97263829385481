import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Link } from "react-router-dom";

const styles = makeStyles((theme) => ({
  blogContainer: {
    display: "flex",
    flexDirection: "column",
  },
  blogMainHeader: {
    padding: "1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 3px",
    },
  },
  blogMainTitle: {
    fontWeight: "bold",
    fontSize: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  subTitle: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  blogMainDate: {
    opacity: "0.7",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
  },
  blogMainAuthor: {
    backgroundColor: theme.palette.secondary.main,
    padding: "3px 10px",
    color: theme.palette.secondary.cotrastText,
    borderRadius: "10px",
    fontWeight: "bold",
  },
  blogMainText: {
    padding: "1rem 0 3rem 1rem",
    lineHeight: "1.75",
    fontFamily: "Roboto",
    fontSize: "1.16rem",

    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0 3rem 0",
      lineHeight: "1.62",
      fontSize: "1rem",
      wordBreak: "break-all",
    },
  },
  blogDevider: {
    backgroundColor: "#999",
  },
}));
const BlogPost = ({ data }) => {
  const classes = styles();
  return (
    <div className={classes.blogContainer}>
      <div className={classes.blogMainHeader}>
        <div className={classes.blogMainTitle}>{data.title}</div>
        <div className={classes.subTitle}>
          <div className={classes.blogMainDate}>{data.createdAt}</div>
          <span className={classes.blogMainDate}>&nbsp;by&nbsp;</span>
          <Link className={classes.blogMainAuthor} to={"/login"}>
            {data.author.name}
          </Link>
        </div>
      </div>
      <Divider variant="fullWidth" classes={{ root: classes.blogDevider }} />
      <div
        className={classes.blogMainText}
        dangerouslySetInnerHTML={{ __html: data.text }}
      ></div>
    </div>
  );
};

export default BlogPost;
