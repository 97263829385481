import { useEffect, Fragment } from "react";
import { CircularProgress, Container, makeStyles } from "@material-ui/core";
import Contact from "./Contact";
import Info from "./Info";
import CustomSimpleCard from "../../custom/CustomSimpleCard";
import Center from "../../../utils/Center";
import { connect } from "react-redux";
import { getAllSkills } from "../../../redux/actions/userAction";
import { Helmet } from "react-helmet";
//! styles
const styles = makeStyles((theme) => ({
  cardsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",
    gap: "2rem",
    margin: "2rem ",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(2,1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(1,1fr)",
    },
  },
  root: {
    maxWidth: 345,
    borderRadius: "8px",
  },
  specializeTitle: {
    margin: "2rem auto",
    fontSize: "2rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  specializeText: {
    margin: "2rem auto",
    fontSize: "1.4rem",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      textAlign: "center",
      margin: "1rem auto",
    },
  },
  // Contact page
  contactPageContainer: {
    backgroundColor: theme.palette.common.mainBackground,
  },
  noSkillStyle: {
    fontFamily: "Roboto",
    margin: "3rem auto",
    padding: "1rem",
    fontSize: "2rem",
    color: theme.palette.common.redError,
  },
}));

//! main Function
const About = ({ isLoading, getAllSkills, skills }) => {
  const classes = styles();

  useEffect(() => {
    getAllSkills();
  }, []);

  if (isLoading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }
  return (
    <Fragment>
      <Helmet>
        <title>Saman | About</title>
        <meta name="description" content="Portfolio Saman Ghanbari" />
      </Helmet>
      <div
        style={{
          color: "#333",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <h1 className={classes.specializeTitle}>
          I'm pretty comfortable with these technologies
        </h1>
        <h3 className={classes.specializeText}>
          In this point of time i use these Technologies on daily basis and it
          could and probably would change
        </h3>

        {skills && skills.length > 0 ? (
          <Container className={classes.cardsContainer}>
            {skills.map((item) => (
              <CustomSimpleCard data={item} key={item._id} />
            ))}
          </Container>
        ) : (
          <h1 className={classes.noSkillStyle}>
            There are no info to be shown right now OR server got down for some
            reason! Sorry eitherway
          </h1>
        )}
      </div>
      <div>
        <Contact />
      </div>
      <div>
        <Info />
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.users.loading,
  skills: state.users.skills,
});
export default connect(mapStateToProps, { getAllSkills })(About);
